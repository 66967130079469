import React from 'react';
import { Helmet } from 'react-helmet';
import { FaAngleLeft } from 'react-icons/fa';
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core';
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

export const SplashPageTemplate = ({
  title,
  subheading,
  backlink,
  description,
  children,
  helmet,
  html
}) => (
  <div>
    { helmet || '' }
    <div className="hero">
      <div className="container-thin">
        { backlink &&
          <div css={css`text-align: left; margin-top: 2rem`}>
            <Link to={ backlink } css={css`:hover, :visited { color: #FFF }`}>
              <FaAngleLeft css={css`padding-top: .25rem;`} />Back
            </Link>
          </div>
        }
        <h1 className="splash__title">{title}</h1>
        <h3 className="splash__subtitle">{subheading}</h3>
      </div>
    </div>
    <section className="section section--gradient">
      { html &&
        <div className="container-thin">
         <HTMLContent content={ html } />
        </div>
      }
      { children }
    </section>
  </div>
)

SplashPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  backlink: PropTypes.string,
}

const SplashPage = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  const { html } = props.data.markdownRemark;

  return (
    <Layout navBar={{ style: 'light', position: 'floating' }}>
      <SplashPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        backlink={frontmatter.backlink}
        description={frontmatter.description}
        html={html}
        children={props.children}
        helmet={
          <Helmet titleTemplate="%s | Homebox">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

SplashPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SplashPage

export const pageQuery = graphql`
  query SplashPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        description
      }
    }
  }
`
